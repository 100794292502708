import React, { useState, useEffect } from "react";


function DisplayDonateCompletePage (){
  
    return (
       <>
       <h2>HOLA!!!</h2>
       <p>Thanks for the donation. How would you like your receipt?</p>
       </>
    )
}

export default DisplayDonateCompletePage;